/*
*
*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * acuta:
 *   - http://typekit.com/eulas/000000000000000077359bb8
 *   - http://typekit.com/eulas/000000000000000077359bbe
 *   - http://typekit.com/eulas/000000000000000077359bc1
 *   - http://typekit.com/eulas/000000000000000077359bc8
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-12-05 06:40:31 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=cvh2zsn&ht=tk&f=7293.7294.7295.7296&a=290802&app=typekit&e=css");

@font-face {
  font-family: "acuta";
  src: url("https://use.typekit.net/af/6dd23d/000000000000000077359bb8/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6dd23d/000000000000000077359bb8/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6dd23d/000000000000000077359bb8/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "acuta";
  src: url("https://use.typekit.net/af/59e3eb/000000000000000077359bbe/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/59e3eb/000000000000000077359bbe/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/59e3eb/000000000000000077359bbe/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "acuta";
  src: url("https://use.typekit.net/af/ea8516/000000000000000077359bc1/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ea8516/000000000000000077359bc1/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ea8516/000000000000000077359bc1/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "acuta";
  src: url("https://use.typekit.net/af/667ede/000000000000000077359bc8/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/667ede/000000000000000077359bc8/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/667ede/000000000000000077359bc8/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

.tk-acuta {
  font-family: "acuta", serif;
}
